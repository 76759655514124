import {
  BrowserRouter as Router,
  Link, 
  Route, 
  Switch
} from 'react-router-dom';
import About from '../../pages/About';
import Contact from '../../pages/Contact';
import Home from '../../pages/Home';
import '../../styles/Nav.css';

export default function Nav() {
    return (
        <Router>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
             <div className="container">
               <Link to="/" className="navbar-brand"><h1>HOTSHOT</h1></Link>
               <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                 <span className="navbar-toggler-icon"></span>
               </button>
               <div className="collapse navbar-collapse nav-list" id="navbarNav">
                 <ul className="navbar-nav">
                   <li className="nav-item">
                     <Link to="/" className="nav-link">HOME</Link>
                   </li>
                   <li className="nav-item">
                     <Link to="/about" className="nav-link">ABOUT US</Link>
                   </li>
                   <li className="nav-item">
                     <Link to="/contact" className="nav-link">CONTACT US</Link>
                   </li>
                 </ul>
              </div>
            </div>
          </nav>
          <div>
            {/*
              A <Switch> looks through all its children <Route>
              elements and renders the first one whose path
              matches the current URL. Use a <Switch> any time
              you have multiple routes, but you want only one
              of them to render at a time
            */}
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
            </Switch>
          </div>
        </Router>
      );
}