import '../styles/About.css';
import about_1 from '../img/about-1.png';

export default function About() {
    return (
        <div className="about-container container">
            <div className='mt-5'>
                <h5 className='mb-3 text-custom-secondary text-left'>OUR MISSION</h5>

                <div className='row'>
                    <div className='col-md-6'>
                        <img src={about_1} alt='our-mission-1'/>
                    </div>

                    <div className='col-md-6'>
                        <p>Welcome to Hotshot Agent. We are an established and reputable boutique agency offering our clients a premium brand along with first class service and excellent value for money.</p>
                        <p>We are a team of highly skilled experienced agents with excellent local knowledge which ensures us offering industry leading service and advice to our clients. We are a modern and innovative brand embracing new technology to deliver services and products never imagined possible only a few years ago.</p>
                        <p>Our highly professional team of sales agents and property managers are consistently praised by our clients for delivering the very best results. We are able to achieve this thanks to the fusion of our values being integrity, enthusiasm and energy, backed by years of proven success in both sales and property management.</p>
                        <p>Our premium brand has been implemented to best represent our clients properties and ever changing marketplace. We strive to achieve outstanding results through an ethos of enthusiasm and honesty which has been the hallmark of our past successes.
                            Hotshot agent offers the most up to date marketing methods to allow for maximum exposure in a professional manner whist offering exceptional value for money.
                            Our clients are guaranteed complete peace of mind when opting for any of the premium services offered, including:</p>
                        <ul>
                            <li>Residential Sales</li>
                            <li>Property Management</li>
                            <li>Land Sales</li>
                            <li>Auctions</li>
                        </ul>
                        <p>We welcome your enquiry and look forward to offering you our no obligation expertise in any of your property needs.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}