import '../../styles/Nav.css';

export default function Footer() {
    return (
        <footer className="pt-5 text-center my-5">
            <p>COPYRIGHT © 2022 HOTSHOT - ALL RIGHTS RESERVED.</p>

            <div className="row justify-content-md-center nav-footer mt-4">
                <a href="/" className="col-md-1">HOME</a>
                <a href="/about" className="col-md-1">ABOUT US</a>
                <a href="/contact" className="col-md-1">CONTACT</a>
            </div>
        </footer>
    );
}