import '../styles/Home.css';
import mission_1 from '../img/mission-1.png';
import mission_2 from '../img/mission-2.png';
import mission_3 from '../img/mission-3.png';

export default function Home() {
    return (
        <div className="container mt-3">
            <div className="row">
                <div className="col-md-8 mx-auto px-0">
                    <video data-aid="HEADER_VIDEO" type="video/mp4" poster="https://img1.wsimg.com/isteam/videos/ubwALx0YVVfn7Qly4" 
                        src="https://categories.api.godaddy.com/v4/videos/raw/video/ubwALx0YVVfn7Qly4"
                        autoPlay 
                        loop 
                        muted 
                        playsInline 
                    ></video>
                </div>

                <div className="col-md-4 mx-auto text-center banner-right">
                    <h5 className='banner-welcome'>WELCOME</h5>
                    <h1 className='my-5 banner-font'>Second-to-none Buying, Selling and Property Management Services</h1>
                    <h5 className='banner-breeze'>We make real estate a breeze!</h5>
                </div>
            </div>

            <div className='mt-5 text-center'>
                <h5 className='mb-3 text-custom-primary'>Welcome</h5>
                <p>There's much to see here!</p>
                <p>So, take your time, look around, and learn all there is to know about us.</p>
                <p>We hope you enjoy our site and take a moment to drop us a line.</p>
            </div>

            <div className='mt-5'>
                <h5 className='mb-3 text-custom-secondary text-left'>OFF MARKET LISTINGS</h5>

                <div className='row'>
                    <div className='col-md-4 p-5 text-center listing-block listing-block-1'>
                        <div className='my-5'>207/261 Morrison Road Ryde NSW 2112</div>
                        <div><b>2 bed/2 bath/1car</b></div>
                    </div>

                    <div className='col-md-4 p-5 text-center listing-block listing-block-2'>
                        <div className='my-5'>D2014/2D Porter Street Meadowbank NSW 2114</div>
                        <div><b>2 bed/2 bath/1car</b></div>
                    </div>

                    <div className='col-md-4 p-5 text-center listing-block listing-block-3'>
                        <div className='my-5'>Level 23, Pacific Highway St Leonards NSW 2065</div>
                        <div><b>2 bed/2 bath/1car</b></div>
                    </div>
                </div>
            </div>

            <div className='mt-5'>
                <h5 className='mb-3 text-custom-secondary text-left'>OUR MISSION</h5>

                <div className='row'>
                    <div className='col-md-6 text-center'>
                        <img src={mission_1} alt='our-mission-1'/>
                    </div>

                    <div className='col-md-6 p-5 text-center'>
                        <h5 className='mb-3 text-custom-primary'>Real Estate Services</h5>
                        <p>Our sales and rental offices are highly trained and experienced local experts dedicated to providing specialist real estate services. Our agents are recruited, and not solicited. Our agents are trained rigorously and enabled by extraordinary access to the market with a superb inventory of listings and a highly functional database.</p>
                    </div>
                </div>

                <div className='row mt-5'>
                    <div className='col-md-6 p-5 text-center'>
                        <h5 className='mb-3 text-custom-primary'>Unbeatable Customer Service</h5>
                        <p>Markets change every day, so you need the property managers who knows the market and works closely with you at every step to adjust the strategy in order to ensure your best interest timely. Remarkable customer services are the central of our services, which we believe it is what our valuable clients are looking for.</p>
                        <p>Through all the ups and downs, the highs and lows, the one thing you can always count on is our outstanding customer service to you. </p>
                    </div>

                    <div className='col-md-6 text-center'>
                        <img src={mission_2} alt='our-mission-1'/>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6 text-center'>
                        <img src={mission_3} alt='our-mission-1'/>
                    </div>

                    <div className='col-md-6 pt-3 text-center'>
                        <h5 className='mb-3 text-custom-primary'>Our Mission</h5>
                        <p>We believe that superior customer service underpins everything else they do. It's of little value having exceptional marketing strategies to draw people to the business if the customer experience does not match the rhetoric.</p>
                        <p>Superior customer service leads to a good reputation for which the company is renowned, but this has not come about by accident. A good reputation is earned. Hotshot has the systems in place to ensure that delivery of excellent service is consistent, monitored and revised in line with feedback.</p>
                    </div>
                </div>
            </div>

            <div className='mt-5'>
                <h5 className='mb-3 text-custom-secondary text-left'>OUR SERVICES</h5>

                <div className='row'>
                    <div className='col-md-6 pt-2 text-center listing-block service-block-1'>
                        <div className='my-5 display-5'>What areas do we cover?</div>
                        <div>Sydney, Sydney Northshore and other areas. Contact us to discuss.</div>
                    </div>

                    <div className='col-md-6 pt-2 text-center listing-block service-block-2'>
                        <div className='my-5 display-5'>Straight Forward Pricing</div>
                        <div>At Hotshot, we employ a simple and transparent fee structure to provide a clear understanding of every single cent you are paying for.</div>
                    </div>
                </div>
            </div>

            <div className='mt-5 pb-5 contact-session'>
                <div className='py-5 text-white text-center display-5'>Call or Visit</div>

                <div className='contact-card text-center'>
                    <h3>Hotshot Pty Ltd</h3>
                    <p className='mb-2'><a className='text-custom-secondary' href="tel:+61280057533">02 8005 7533</a></p>
                    <p><a className="text-custom-secondary mb-3" href="mailto:info@thehotshot.com.au">info@thehotshot.com.au</a></p>
                    <h5>Monday - Saturday: 8:30am - 5pm</h5>
                    <h5>Sunday: Closed</h5>

                    <a href='/contact' className='mt-5 contact-button'>DROP US A LINE!</a>
                </div>
            </div>
        </div>
    );
}