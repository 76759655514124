import React from 'react';
import Swal from 'sweetalert2';
import '../styles/Contact.css';

class Contact extends React.Component {    
    handleSubmit() {
        Swal.fire({
            icon: 'success',
            title: 'Your message has been sent',
            showConfirmButton: false,
            timer: 1500
          })
    }

    render() {
        return (
        <div className="container">
            <div className='mt-5'>
                <h5 className='mb-3 text-custom-secondary text-left'>CONTACT US</h5>

                <div className='row'>
                    <div className='col-md-6 p-5'>
                        <h5 className='text-left'>CONTACT US!</h5>
                        <form id='message'>
                            <input type="text" className="form-control form-input my-5" id="inputName" aria-describedby="name" placeholder="Name" />
                            <input type="email" className="form-control form-input my-5" id="inputEmail" placeholder="Email" />
                            <textarea className='form-textarea mb-5' name="message" form="message" placeholder='Message'></textarea>
                            <div><a type="submit" className="submit-button" onClick={this.handleSubmit}>SEND</a></div>
                            <div className='mt-3'>
                                <small>This site is protected by reCAPTCHA and the Google 
                                <a className='contact-link' target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> 
                                and <a className='contact-link' target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.
                                </small>
                            </div>
                        </form>
                    </div>

                    <div className='col-md-6 p-5'>
                        <h5 className='mb-3 text-custom-primary'>Better yet, see us in person!</h5>
                        <p>We love our customers, so feel free to visit during normal business hours by appointments.</p>

                        <h5 className='mt-5 mb-3 text-custom-primary'>Hotshot Pty Ltd</h5>
                        <p>Suite 401B, Level 4, North Tower, 1-5 Railway Street, Chatswood NSW 2067</p>
                        <p><a className='contact-link' href="tel:+61280057533">02 8005 7533</a></p>
                        <p><a className="contact-link" href="mailto:info@thehotshot.com.au">info@thehotshot.com.au</a></p>
                        
                        <h5 className='mt-5 mb-3 text-custom-primary'>Business Hour</h5>
                        <p>Monday - Saturday: 8:30am - 5pm</p>
                        <p>Sunday: Closed</p>
                    </div>
                </div>
            </div>

            <div className='mt-5'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1657.8110522686188!2d151.17932480806957!3d-33.79625554898015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a8d38cc3f671%3A0x2151727ff8e55f!2sTower%2C%201%2F5%20Railway%20St%2C%20Chatswood%20NSW%202067!5e0!3m2!1sen!2sau!4v1708149016808!5m2!1sen!2sau" width="600" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
        </div>
        );
    };
}

export default Contact;

